import React from "react";

const Experience = () => {
  return (
    <div className="left-bottom-border">
      <h3>My Experience</h3>
      <p>I have been in the developer field since 2015, working closely with Adapt (An eLearning Framework). I have grown passionate about the field and develop my skills, resulting in teaching myself HTML, CSS, JavaScript and React.<br /><br />Growing up I wanted to be an Interior Designer which changed to web design during my college art course. I feel this design background gives me a creative edge while coding websites. I bring an outside the box thought process whilst having the knowledge of what's possible within the world of web design.</p>
    </div>
  );
};

export default Experience;