import React from "react";


const KIconAnimation = () => {
  return (
    <div className="header-title">
      <svg className="k-icon-animated" viewBox="0 0 240 240" >
        <path
          d="M70.6 41.9s20.1 128.8 26.5 128.8c6.3 0 29.9-118.8 26.5-118.8s-4.1 42.5-10.2 69.9c-3.1 14.2-11.3 48.9-16.3 48.9-4.7 0 20.3-44 39.4-24.9s33.4 36.2 44.4 31.4 8.2-18.1 8.2-18.1"/>
      </svg>
      <h1>ayla C</h1>
    </div>
  );
};

export default KIconAnimation;
